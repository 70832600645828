.product {
  margin: 0;
}

.product.grid {
  gap: 0;
}

.product--no-media {
  max-width: 57rem;
  margin: 0 auto;
}

.product__media-wrapper {
  padding-left: 0;
}

@media screen and (min-width: 750px) {
  .product__column-sticky {
    display: block;
    position: sticky;
    top: 3rem;
    z-index: 2;
  }

  .product--thumbnail .thumbnail-list {
    padding-right: var(--media-shadow-horizontal-offset);
  }

  .product__info-wrapper {
    padding: 0 0 0 5rem;
  }

  .product__info-wrapper--extra-padding {
    padding: 0 0 0 8rem;
  }

  .product--right .product__info-wrapper {
    padding: 0 5rem 0 0;
  }

  .product--right .product__info-wrapper--extra-padding {
    padding: 0 8rem 0 0;
  }

  .product--right .product__media-list {
    margin-bottom: 2rem;
  }

  .product__media-container .slider-buttons {
    display: none;
  }
}

@media screen and (min-width: 990px) {
  .product--large:not(.product--no-media) .product__media-wrapper {
    max-width: 65%;
    width: calc(65% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .product--large:not(.product--no-media) .product__info-wrapper {
    padding: 0 0 0 4rem;
    max-width: 35%;
    width: calc(35% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .product--large:not(.product--no-media).product--right .product__info-wrapper {
    padding: 0 4rem 0 0;
  }

  .product--medium:not(.product--no-media) .product__media-wrapper,
  .product--small:not(.product--no-media) .product__info-wrapper {
    max-width: 55%;
    width: calc(55% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .product--medium:not(.product--no-media) .product__info-wrapper,
  .product--small:not(.product--no-media) .product__media-wrapper {
    max-width: 45%;
    width: calc(45% - var(--grid-desktop-horizontal-spacing) / 2);
  }
}

/* Dynamic checkout */

.shopify-payment-button__button {
  font-family: inherit;
  min-height: 4.6rem;
}

.shopify-payment-button__button [role='button'].focused,
.no-js .shopify-payment-button__button [role='button']:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5) !important;
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-button), var(--alpha-button-border)),
    0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3) !important;
}

.shopify-payment-button__button [role='button']:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.shopify-payment-button__button [role='button']:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5) !important;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-button), var(--alpha-button-border)),
    0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3) !important;
}

.shopify-payment-button__button--unbranded {
  background-color: rgba(var(--color-button), var(--alpha-button-background));
  color: rgb(var(--color-button-text));
  font-size: 1.4rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  letter-spacing: 0.07rem;
}

.shopify-payment-button__button--unbranded::selection {
  background-color: rgba(var(--color-button-text), 0.3);
}

.shopify-payment-button__button--unbranded:hover,
.shopify-payment-button__button--unbranded:hover:not([disabled]) {
  background-color: rgba(var(--color-button), var(--alpha-button-background));
}

.shopify-payment-button__more-options {
  margin: 1.6rem 0 1rem;
  font-size: 1.2rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  letter-spacing: 0.05rem;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.shopify-payment-button__button + .shopify-payment-button__button--hidden {
  display: none;
}

/* Product form */

.product-form {
  display: block;
}

.product-form__error-message-wrapper:not([hidden]) {
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.product-form__error-message-wrapper svg {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.7rem;
  margin-top: 0.5rem;
}

/* Form Elements */
.product-form__input {
  flex: 0 0 100%;
  padding: 0;
  margin: 0 0 1.2rem 0;
  max-width: 44rem;
  min-width: fit-content;
  border: none;
}

variant-radios,
variant-selects {
  display: block;
}

.product-form__input--dropdown {
  margin-bottom: 1.6rem;
}

.product-form__input .form__label {
  padding-left: 0;
}

fieldset.product-form__input .form__label {
  margin-bottom: 0.2rem;
}

.product-form__input input[type='radio'] {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.product-form__input input[type='radio'] + label {
  border: var(--variant-pills-border-width) solid rgba(var(--color-foreground), var(--variant-pills-border-opacity));
  background-color: rgb(var(--color-background));
  color: rgba(var(--color-foreground));
  border-radius: var(--variant-pills-radius);
  color: rgb(var(--color-foreground));
  display: inline-block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  transition: border var(--duration-short) ease;
  cursor: pointer;
  position: relative;
}

.product-form__input input[type='radio'] + label:before {
  content: '';
  position: absolute;
  top: calc(var(--variant-pills-border-width) * -1);
  right: calc(var(--variant-pills-border-width) * -1);
  bottom: calc(var(--variant-pills-border-width) * -1);
  left: calc(var(--variant-pills-border-width) * -1);
  z-index: -1;
  border-radius: var(--variant-pills-radius);
  box-shadow: var(--variant-pills-shadow-horizontal-offset) var(--variant-pills-shadow-vertical-offset)
    var(--variant-pills-shadow-blur-radius) rgba(var(--color-shadow), var(--variant-pills-shadow-opacity));
}

.product-form__input input[type='radio'] + label:hover {
  border-color: rgb(var(--color-foreground));
}

.product-form__input input[type='radio']:checked + label {
  background-color: rgb(var(--color-foreground));
  color: rgb(var(--color-background));
}

.product-form__input input[type='radio']:not(.disabled) + label > .visually-hidden {
  display: none;
}

@media screen and (forced-colors: active) {
  .product-form__input input[type='radio']:checked + label {
    text-decoration: underline;
  }
}

.product-form__input input[type='radio']:checked + label::selection {
  background-color: rgba(var(--color-background), 0.3);
}

.product-form__input input[type='radio']:disabled + label,
.product-form__input input[type='radio'].disabled + label {
  border-color: rgba(var(--color-foreground), 0.1);
  color: rgba(var(--color-foreground), 0.6);
  text-decoration: line-through;
}

.product-form__input input[type='radio'].disabled:checked + label,
.product-form__input input[type='radio']:disabled:checked + label {
  color: rgba(var(--color-background), 0.6);
}
.product-form__input input[type='radio']:focus-visible + label {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0 0.5rem rgba(var(--color-foreground), 0.55);
}

/* Fallback */
.product-form__input input[type='radio'].focused + label,
.no-js .shopify-payment-button__button [role='button']:focus + label {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0 0.5rem rgba(var(--color-foreground), 0.55);
}

/* No outline when focus-visible is available in the browser */
.no-js .product-form__input input[type='radio']:focus:not(:focus-visible) + label {
  box-shadow: none;
}

.product-form__input .select {
  max-width: 25rem;
}

.product-form__submit {
  margin-bottom: 1rem;
}

.no-js .product-form__submit.button--secondary {
  --color-button: inherit;
  --color-button-text: inherit;
  --alpha-button-background: 1;
}

.product-form__submit[aria-disabled='true'] + .shopify-payment-button .shopify-payment-button__button[disabled],
.product-form__submit[disabled] + .shopify-payment-button .shopify-payment-button__button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

@media screen and (forced-colors: active) {
  .product-form__submit[aria-disabled='true'] {
    color: Window;
  }
}

/* Overrides */
.shopify-payment-button__more-options {
  color: rgb(var(--color-foreground));
}

.shopify-payment-button__button {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}

/* Product info */

.product__info-container > * + * {
  margin: 1.5rem 0;
}

.product__info-container iframe {
  max-width: 100%;
}

.product__info-container .product-form,
.product__info-container .product__description,
.product__info-container .icon-with-text {
  margin: 2.5rem 0;
}

.product__text {
  margin-bottom: 0;
}

a.product__text {
  display: block;
  text-decoration: none;
  color: rgba(var(--color-foreground), 0.75);
}

.product__text.caption-with-letter-spacing {
  text-transform: uppercase;
}

.product__title {
  word-break: break-word;
  margin-bottom: 1.5rem;
}

.product__title > * {
  margin: 0;
}

.product__title > a {
  display: none;
}

.product__title + .product__text.caption-with-letter-spacing {
  margin-top: -1.5rem;
}

.product__text.caption-with-letter-spacing + .product__title {
  margin-top: 0;
}

.product__accordion .accordion__content {
  padding: 0 1rem;
}

.product .price .badge {
  margin-bottom: 0.5rem;
}

.product .price__container {
  margin-bottom: 0.5rem;
}

.product .price dl {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.product .price--sold-out .price__badge-sale {
  display: none;
}

@media screen and (min-width: 750px) {
  .product__info-container {
    max-width: 60rem;
  }

  .product__info-container .price--on-sale .price-item--regular {
    font-size: 1.6rem;
  }

  .product__info-container > *:first-child {
    margin-top: 0;
  }
}

.product__description-title {
  font-weight: 600;
}

.product--no-media .product__title,
.product--no-media .product__text,
.product--no-media noscript .product-form__input,
.product--no-media .product__tax,
.product--no-media .product__sku,
.product--no-media shopify-payment-terms {
  text-align: center;
}

.product--no-media .product__media-wrapper,
.product--no-media .product__info-wrapper {
  padding: 0;
}

.product__tax {
  margin-top: -1.4rem;
}

.product--no-media noscript .product-form__input,
.product--no-media .share-button {
  max-width: 100%;
}

.product--no-media fieldset.product-form__input,
.product--no-media .product-form__quantity,
.product--no-media .product-form__input--dropdown,
.product--no-media .share-button,
.product--no-media .product__view-details,
.product--no-media .product__pickup-availabilities,
.product--no-media .product-form {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product--no-media .product-form {
  flex-direction: column;
}

.product--no-media .product-form > .form {
  max-width: 30rem;
  width: 100%;
}

.product--no-media .product-form__quantity,
.product--no-media .product-form__input--dropdown {
  flex-direction: column;
  max-width: 100%;
}

.product-form__quantity .form__label {
  margin-bottom: 0.6rem;
}

.price-per-item__label.form__label {
  margin-bottom: 0rem;
}

.product-form__quantity-top .form__label {
  margin-bottom: 1.2rem;
}

.product--no-media fieldset.product-form__input {
  flex-wrap: wrap;
  margin: 0 auto 1.2rem auto;
}

.product-form__buttons {
  max-width: 44rem;
}

.product--no-media .product__info-container > modal-opener {
  display: block;
  text-align: center;
}

.product--no-media .product-popup-modal__button {
  padding-right: 0;
}

.product--no-media .price {
  text-align: center;
}

/* Product media */
.product__media-list video {
  border-radius: calc(var(--media-radius) - var(--media-border-width));
}

@media screen and (max-width: 749px) {
  .product__media-list {
    margin-left: -2.5rem;
    margin-bottom: 3rem;
    width: calc(100% + 4rem);
  }

  .product__media-wrapper slider-component:not(.thumbnail-slider--no-slide) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .slider.product__media-list::-webkit-scrollbar {
    height: 0.2rem;
    width: 0.2rem;
  }

  .product__media-list::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-foreground));
  }

  .product__media-list::-webkit-scrollbar-track {
    background-color: rgba(var(--color-foreground), 0.2);
  }

  .product__media-list .product__media-item {
    width: calc(100% - 3rem - var(--grid-mobile-horizontal-spacing));
  }

  .product--mobile-columns .product__media-item {
    width: calc(50% - 1.5rem - var(--grid-mobile-horizontal-spacing));
  }
}

@media screen and (min-width: 750px) {
  .product--thumbnail .product__media-list,
  .product--thumbnail_slider .product__media-list {
    padding-bottom: calc(var(--media-shadow-vertical-offset) * var(--media-shadow-visible));
  }

  .product__media-list {
    padding-right: calc(var(--media-shadow-horizontal-offset) * var(--media-shadow-visible));
  }

  .product--thumbnail .product__media-item:not(.is-active),
  .product--thumbnail_slider .product__media-item:not(.is-active) {
    display: none;
  }

  .product-media-modal__content > .product__media-item--variant.product__media-item--variant {
    display: none;
  }

  .product-media-modal__content > .product__media-item--variant:first-child {
    display: block;
  }
}

.product__media-item.product__media-item--variant {
  display: none;
}

.product__media-item--variant:first-child {
  display: block;
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .product__media-list .product__media-item:first-child {
    padding-left: 0;
  }

  .product--thumbnail_slider .product__media-list {
    margin-left: 0;
  }

  .product__media-list .product__media-item {
    width: 100%;
  }
}

.product__media-icon .icon {
  width: 1.2rem;
  height: 1.4rem;
}

.product__media-icon,
.thumbnail__badge {
  background-color: rgb(var(--color-background));
  border-radius: 50%;
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  color: rgb(var(--color-foreground));
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  position: absolute;
  left: 1.2rem;
  top: 1.2rem;
  z-index: 1;
  transition: color var(--duration-short) ease, opacity var(--duration-short) ease;
}

.product__media-video .product__media-icon {
  opacity: 1;
}

.product__modal-opener--image .product__media-toggle:hover {
  cursor: zoom-in;
}

.product__modal-opener:hover .product__media-icon {
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
}

@media screen and (min-width: 750px) {
  .grid__item.product__media-item--full {
    width: 100%;
  }

  .product--columns .product__media-item:not(.product__media-item--single):not(:only-child) {
    max-width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .product--large.product--columns .product__media-item--full .deferred-media__poster-button {
    height: 5rem;
    width: 5rem;
  }

  .product--medium.product--columns .product__media-item--full .deferred-media__poster-button {
    height: 4.2rem;
    width: 4.2rem;
  }

  .product--medium.product--columns .product__media-item--full .deferred-media__poster-button .icon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .product--small.product--columns .product__media-item--full .deferred-media__poster-button {
    height: 3.6rem;
    width: 3.6rem;
  }

  .product--small.product--columns .product__media-item--full .deferred-media__poster-button .icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@media screen and (min-width: 990px) {
  .product--stacked .product__media-item {
    max-width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .product:not(.product--columns) .product__media-list .product__media-item:first-child,
  .product:not(.product--columns) .product__media-list .product__media-item--full {
    width: 100%;
    max-width: 100%;
  }

  .product__modal-opener .product__media-icon {
    opacity: 0;
  }

  .product__modal-opener:hover .product__media-icon,
  .product__modal-opener:focus .product__media-icon {
    opacity: 1;
  }
}

.product__media-item > * {
  display: block;
  position: relative;
}

.product__media-toggle {
  display: flex;
  border: none;
  background-color: transparent;
  color: currentColor;
  padding: 0;
}

.product__media-toggle::after {
  content: '';
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  bottom: calc(var(--border-width) * -1);
  left: calc(var(--border-width) * -1);
  z-index: 2;
}

.product__media-toggle:focus-visible {
  outline: 0;
  box-shadow: none;
}

/* outline styling for Windows High Contrast Mode */
@media (forced-colors: active) {
  .product__media-toggle:focus-visible,
  .product__media-toggle:focus-visible:after,
  .product-form__input input[type='radio']:focus-visible + label {
    outline: transparent solid 1px;
    outline-offset: 2px;
  }
}
.product__media-toggle.focused {
  outline: 0;
  box-shadow: none;
}

.product__media-toggle:focus-visible:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0rem 0.5rem rgba(var(--color-foreground), 0.5);
  border-radius: var(--media-radius);
}

.product__media-toggle.focused:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0rem 0.5rem rgba(var(--color-foreground), 0.5);
  border-radius: var(--media-radius);
}

.product-media-modal {
  background-color: rgb(var(--color-background));
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.product-media-modal[open] {
  visibility: visible;
  opacity: 1;
  z-index: 101;
}

.product-media-modal__dialog {
  display: flex;
  align-items: center;
  height: 100vh;
}

.product-media-modal__content {
  max-height: 100vh;
  width: 100%;
  overflow: auto;
}

.product-media-modal__content > *:not(.active),
.product__media-list .deferred-media {
  display: none;
}

@media screen and (min-width: 750px) {
  .product-media-modal__content {
    padding-bottom: 2rem;
  }

  .product-media-modal__content > *:not(.active) {
    display: block;
  }

  .product__modal-opener:not(.product__modal-opener--image) {
    display: none;
  }

  .product__media-list .deferred-media {
    display: block;
  }
}

@media screen and (max-width: 749px) {
  .product--thumbnail .is-active .product__modal-opener:not(.product__modal-opener--image),
  .product--thumbnail_slider .is-active .product__modal-opener:not(.product__modal-opener--image) {
    display: none;
  }

  .product--thumbnail .is-active .deferred-media,
  .product--thumbnail_slider .is-active .deferred-media {
    display: block;
    width: 100%;
  }
}

.product-media-modal__content > * {
  display: block;
  height: auto;
  margin: auto;
}

.product-media-modal__content .media {
  background: none;
}

.product-media-modal__model {
  width: 100%;
}

.product-media-modal__toggle {
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 2rem;
  padding: 1.2rem;
  position: fixed;
  z-index: 2;
  top: 2rem;
  width: 4rem;
}

.product-media-modal__content .deferred-media {
  width: 100%;
}

@media screen and (min-width: 750px) {
  .product-media-modal__content {
    padding: 2rem 11rem;
  }

  .product-media-modal__content > * {
    width: 100%;
  }

  .product-media-modal__content > * + * {
    margin-top: 2rem;
  }

  .product-media-modal__toggle {
    right: 5rem;
    top: 2.2rem;
  }
}

@media screen and (min-width: 990px) {
  .product-media-modal__content {
    padding: 2rem 11rem;
  }

  .product-media-modal__content > * + * {
    margin-top: 1.5rem;
  }

  .product-media-modal__content {
    padding-bottom: 1.5rem;
  }

  .product-media-modal__toggle {
    right: 5rem;
  }
}

.product-media-modal__toggle:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.product-media-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

/* Product popup */

.product-popup-modal {
  box-sizing: border-box;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  z-index: -1;
  margin: 0 auto;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  background: rgba(var(--color-foreground), 0.2);
  height: 100%;
}

.product-popup-modal[open] {
  opacity: 1;
  visibility: visible;
  z-index: 101;
}

.product-popup-modal__content {
  border-radius: var(--popup-corner-radius);
  background-color: rgb(var(--color-background));
  overflow: auto;
  height: 80%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5rem;
  width: 92%;
  position: absolute;
  top: 0;
  padding: 0 1.5rem 0 3rem;
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-style: solid;
  border-width: var(--popup-border-width);
  box-shadow: var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
    rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

.product-popup-modal__content.focused {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3),
    var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
      rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

.product-popup-modal__content:focus-visible {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3),
    var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
      rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

@media screen and (min-width: 750px) {
  .product-popup-modal__content {
    padding-right: 1.5rem;
    margin-top: 10rem;
    width: 70%;
    padding: 0 3rem;
  }

  .product-media-modal__dialog .global-media-settings--no-shadow {
    overflow: visible !important;
  }
}

.product-popup-modal__content img {
  max-width: 100%;
}

@media screen and (max-width: 749px) {
  .product-popup-modal__content table {
    display: block;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0;
  }

  .product-media-modal__dialog .global-media-settings,
  .product-media-modal__dialog .global-media-settings video,
  .product-media-modal__dialog .global-media-settings model-viewer,
  .product-media-modal__dialog .global-media-settings iframe,
  .product-media-modal__dialog .global-media-settings img {
    border: none;
    border-radius: 0;
  }
}

.product-popup-modal__opener {
  display: inline-block;
}

.product-popup-modal__button {
  font-size: 1.6rem;
  padding-right: 1.3rem;
  padding-left: 0;
  min-height: 4.4rem;
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness var(--duration-short) ease;
}

.product-popup-modal__button:hover {
  text-decoration-thickness: 0.2rem;
}

.product-popup-modal__content-info {
  padding-right: 4.4rem;
}

.product-popup-modal__content-info > * {
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 749px) {
  .product-popup-modal__content-info > * {
    max-height: 100%;
  }
}

.product-popup-modal__toggle {
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: sticky;
  padding: 1.2rem;
  z-index: 2;
  top: 1.5rem;
  width: 4rem;
  margin: 0 0 0 auto;
}

.product-popup-modal__toggle:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.product-popup-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

.product__media-list .media > * {
  overflow: hidden;
}

.thumbnail-list {
  flex-wrap: wrap;
  grid-gap: 1rem;
}

/* Fix to show some space at the end of our sliders in all browsers to be applied on thumbnails */
.slider--mobile.thumbnail-list:after {
  content: none;
}

@media screen and (min-width: 750px) {
  .product--stacked .thumbnail-list {
    display: none;
  }

  .thumbnail-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.thumbnail-list_item--variant:not(:first-child) {
  display: none;
}

@media screen and (min-width: 990px) {
  .thumbnail-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .product--medium .thumbnail-list {
    grid-template-columns: repeat(5, 1fr);
  }

  .product--large .thumbnail-list {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 749px) {
  .product__media-item {
    display: flex;
    align-items: center;
  }

  .product__modal-opener {
    width: 100%;
  }

  .thumbnail-slider {
    display: flex;
    align-items: center;
  }

  .thumbnail-slider .thumbnail-list.slider {
    display: flex;
    padding: 0.5rem;
    flex: 1;
    scroll-padding-left: 0.5rem;
  }

  .thumbnail-list__item.slider__slide {
    width: calc(33% - 0.6rem);
  }
}

@media screen and (min-width: 750px) {
  .product--thumbnail_slider .thumbnail-slider {
    display: flex;
    align-items: center;
  }

  .thumbnail-slider .thumbnail-list.slider--tablet-up {
    display: flex;
    padding: 0.5rem;
    flex: 1;
    scroll-padding-left: 0.5rem;
  }

  .product__media-wrapper .slider-mobile-gutter .slider-button {
    display: none;
  }

  .thumbnail-list.slider--tablet-up .thumbnail-list__item.slider__slide {
    width: calc(25% - 0.8rem);
  }

  .product--thumbnail_slider .slider-mobile-gutter .slider-button {
    display: flex;
  }
}

@media screen and (min-width: 900px) {
  .product--small .thumbnail-list.slider--tablet-up .thumbnail-list__item.slider__slide {
    width: calc(25% - 0.8rem);
  }

  .thumbnail-list.slider--tablet-up .thumbnail-list__item.slider__slide {
    width: calc(20% - 0.8rem);
  }
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  color: rgb(var(--color-foreground));
  cursor: pointer;
  background-color: transparent;
}

.thumbnail:hover {
  opacity: 0.7;
}

.thumbnail.global-media-settings img {
  border-radius: 0;
}

.thumbnail[aria-current] {
  box-shadow: 0 0 0rem 0.1rem rgb(var(--color-foreground));
  border-color: rgb(var(--color-foreground));
}

.image-magnify-full-size {
  cursor: zoom-out;
  z-index: 1;
  margin: 0;
  border-radius: calc(var(--media-radius) - var(--media-border-width));
}

.image-magnify-hover {
  cursor: zoom-in;
}

.product__modal-opener--image .product__media-zoom-none,
.product__media-icon--none {
  display: none;
}

.product__modal-opener > .loading__spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  align-items: center;
  height: 48px;
  width: 48px;
}

.product__modal-opener .path {
  stroke: rgb(var(--color-button));
  opacity: 0.75;
}

@media (hover: hover) {
  .product__media-zoom-hover,
  .product__media-icon--hover {
    display: none;
  }
}

@media screen and (max-width: 749px) {
  .product__media-zoom-hover,
  .product__media-icon--hover {
    display: flex;
  }
}

.js .product__media {
  overflow: hidden !important;
}

.thumbnail[aria-current]:focus-visible {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0rem 0.5rem rgba(var(--color-foreground), 0.5);
}

.thumbnail[aria-current]:focus,
.thumbnail.focused {
  outline: 0;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0rem 0.5rem rgba(var(--color-foreground), 0.5);
}

/* outline styling for Windows High Contrast Mode */
@media (forced-colors: active) {
  .thumbnail[aria-current]:focus,
  .thumbnail.focused {
    outline: transparent solid 1px;
  }
}
.thumbnail[aria-current]:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(var(--color-foreground));
}

.thumbnail img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.thumbnail__badge .icon {
  width: 1rem;
  height: 1rem;
}

.thumbnail__badge .icon-3d-model {
  width: 1.2rem;
  height: 1.2rem;
}

.thumbnail__badge {
  color: rgb(var(--color-foreground), 0.6);
  height: 2rem;
  width: 2rem;
  left: auto;
  right: calc(0.4rem + var(--media-border-width));
  top: calc(0.4rem + var(--media-border-width));
}

@media screen and (min-width: 750px) {
  .product:not(.product--small) .thumbnail__badge {
    height: 3rem;
    width: 3rem;
  }

  .product:not(.product--small) .thumbnail__badge .icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  .product:not(.product--small) .thumbnail__badge .icon-3d-model {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.thumbnail-list__item {
  position: relative;
}

.thumbnail-list__item::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.product:not(.featured-product) .product__view-details {
  display: none;
}

.product__view-details {
  display: block;
  text-decoration: none;
}

.product__view-details:hover {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.product__view-details .icon {
  width: 1.2rem;
  margin-left: 1.2rem;
  flex-shrink: 0;
}

/* Inventory status */

.product__inventory {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.product--no-media .product__inventory {
  justify-content: center;
}

/* This keeps the container from getting display: none; applied to it and to make sure we're not introducing some layout shift when switching to an unavailable variant */
.product__inventory.visibility-hidden:empty {
  display: block;
}

.product__inventory.visibility-hidden:empty::after {
  content: '#';
}

/* Icon with text */
.icon-with-text {
  --icon-size: calc(var(--font-heading-scale) * 3rem);
  --icon-spacing: calc(var(--font-heading-scale) * 1rem);
}

.icon-with-text--horizontal {
  display: flex;
  justify-content: center;
  column-gap: 3rem;
  flex-direction: row;
}

.icon-with-text--vertical {
  --icon-size: calc(var(--font-heading-scale) * 2rem);
}

.icon-with-text .icon {
  fill: rgb(var(--color-foreground));
  height: var(--icon-size);
  width: var(--icon-size);
}

.icon-with-text--horizontal .icon,
.icon-with-text--horizontal img {
  margin-bottom: var(--icon-spacing);
}

.icon-with-text--vertical .icon {
  min-height: var(--icon-size);
  min-width: var(--icon-size);
  margin-right: var(--icon-spacing);
}

.icon-with-text img {
  height: var(--icon-size);
  width: var(--icon-size);
  object-fit: contain;
}

.icon-with-text--vertical img {
  margin-right: var(--icon-spacing);
}

.icon-with-text--horizontal .h4 {
  padding-top: calc(var(--icon-size) + var(--icon-spacing));
  text-align: center;
}

.icon-with-text--horizontal svg + .h4,
.icon-with-text--horizontal img + .h4,
.icon-with-text--horizontal.icon-with-text--text-only .h4 {
  padding-top: 0;
}

.icon-with-text__item {
  display: flex;
  align-items: center;
}

.icon-with-text--horizontal .icon-with-text__item {
  flex-direction: column;
  width: 33%;
}

.icon-with-text--vertical .icon-with-text__item {
  margin-bottom: var(--icon-size);
}

/* SKU block */

.product__sku.visibility-hidden::after {
  content: '#';
}

/* Product-thumbnail snippet */

.product-media-container {
  --aspect-ratio: var(--preview-ratio);
  --ratio-percent: calc(1 / var(--aspect-ratio) * 100%);
  position: relative;
  width: 100%;
  max-width: calc(100% - calc(var(--media-border-width) * 2));
}

.product-media-container.constrain-height {
  /* arbitrary offset value based on average theme spacing and header height */
  --viewport-offset: 400px;
  --constrained-min-height: 300px;
  --constrained-height: max(var(--constrained-min-height), calc(100vh - var(--viewport-offset)));
  margin-right: auto;
  margin-left: auto;
}

.product-media-container.constrain-height.media-fit-contain {
  --contained-width: calc(var(--constrained-height) * var(--aspect-ratio));
  width: min(var(--contained-width), 100%);
}

.product-media-container .media {
  padding-top: var(--ratio-percent);
}

.product-media-container.constrain-height .media {
  padding-top: min(var(--constrained-height), var(--ratio-percent));
}

@media screen and (max-width: 749px) {
  .product-media-container.media-fit-cover {
    display: flex;
    align-self: stretch;
  }

  .product-media-container.media-fit-cover .media {
    /* allow media img element to scale relative to modal-opener/product-media-container */
    position: initial;
  }
}

@media screen and (min-width: 750px) {
  .product-media-container {
    max-width: 100%;
  }

  .product-media-container:not(.media-type-image) {
    /* override to use actual media ratio (not poster ratio) for video/models on desktop */
    --aspect-ratio: var(--ratio);
  }

  .product-media-container.constrain-height {
    --viewport-offset: 170px;
    --constrained-min-height: 500px;
  }

  .product-media-container.media-fit-cover,
  .product-media-container.media-fit-cover .product__modal-opener,
  .product-media-container.media-fit-cover .media {
    height: 100%;
  }

  .product-media-container.media-fit-cover .deferred-media__poster img {
    object-fit: cover;
    width: 100%;
  }
}

.product-media-container .product__modal-opener {
  display: block;
  position: relative;
}

@media screen and (min-width: 750px) {
  .product-media-container .product__modal-opener:not(.product__modal-opener--image) {
    display: none;
  }
}

/* Recipient form */
.recipient-form {
  /* (2.88[line-height] - 1.6rem) / 2 */
  --recipient-checkbox-margin-top: 0.64rem;

  display: block;
  position: relative;
  max-width: 44rem;
  margin-bottom: 2.5rem;
}

.recipient-form-field-label {
  margin: 0.6rem 0;
}

.recipient-form-field-label--space-between {
  display: flex;
  justify-content: space-between;
}

.recipient-checkbox {
  flex-grow: 1;
  font-size: 1.6rem;
  display: flex;
  word-break: break-word;
  align-items: flex-start;
  max-width: inherit;
  position: relative;
  cursor: pointer;
}

.no-js .recipient-checkbox {
  display: none;
}

.recipient-form > input[type='checkbox'] {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  margin: var(--recipient-checkbox-margin-top) 0;
  top: 0;
  left: 0;
  z-index: -1;
  appearance: none;
  -webkit-appearance: none;
}

.recipient-fields__field {
  margin: 0 0 2rem 0;
}

.recipient-fields .field__label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 3.5rem);
  overflow: hidden;
}

.recipient-checkbox > svg {
  margin-top: var(--recipient-checkbox-margin-top);
  margin-right: 1.2rem;
  flex-shrink: 0;
}

.recipient-form .icon-checkmark {
  visibility: hidden;
  position: absolute;
  left: 0.28rem;
  z-index: 5;
  top: 0.4rem;
}

.recipient-form > input[type='checkbox']:checked + label .icon-checkmark {
  visibility: visible;
}

.js .recipient-fields {
  display: none;
}

.recipient-fields hr {
  margin: 1.6rem auto;
}

.recipient-form > input[type='checkbox']:checked ~ .recipient-fields {
  display: block;
  animation: animateMenuOpen var(--duration-default) ease;
}
.recipient-form > input[type='checkbox']:not(:checked, :disabled) ~ .recipient-fields,
.recipient-email-label {
  display: none;
}

.js .recipient-email-label.required,
.no-js .recipient-email-label.optional {
  display: inline;
}

.recipient-form ul {
  line-height: calc(1 + 0.6 / var(--font-body-scale));
  padding-left: 4.4rem;
  text-align: left;
}

.recipient-form ul a {
  display: inline;
}

.recipient-form .error-message::first-letter {
  text-transform: capitalize;
}

@media screen and (forced-colors: active) {
  .recipient-fields > hr {
    border-top: 0.1rem solid rgb(var(--color-background));
  }

  .recipient-checkbox > svg {
    background-color: inherit;
    border: 0.1rem solid rgb(var(--color-background));
  }

  .recipient-form > input[type='checkbox']:checked + label .icon-checkmark {
    border: none;
  }
}
